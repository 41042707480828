import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import SignUpSection from '../components/signup/signup';
import NavBar from '../components/PublicNav/homenav';

const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 1vh;
`;

const IntroParagraph = styled.p`
  font-size: 4vh;
  font-weight: bold;
  font-family: 'Ubuntu';
  line-height: 1.6;
  max-width: 100%;
  padding-top: 3vh;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 3.5vh; // Space between the paragraph and the first image
  text-align: center;
  @media(orientation: portrait){
    font-size: 2.5vh;
  }
`;
const SmallParagraph = styled.p`
  font-size: 2.2vh;
  line-height: 1.6;
  font-style: italic;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  text-align: center;
  margin-bottom: 13vh;
  @media(orientation: portrait){
    font-size: 2vh;
    width: 40vh;
  }
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 1vh;
`;

const StyledIframe = styled.iframe`
  width: 50vw;      // Responsive width
  height: 23vw; // Responsive height, maintaining a 16:9 aspect ratio
  max-width: 80%; // Maximum size
  max-height: 80%;
  border: none;
  @media(orientation: portrait){
    width: 70vw;
    height: 35vw;
  }
`;

const OtherVideosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const VideoThumbnail = styled.div`
  width: 150px;
  margin: 10px;
  cursor: pointer;
  img {
    width: 100%;
    border-radius: 10px;
  }
  p {
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
    color: #333;
  }
  @media(orientation: portrait){
    width: 15vh;
  }
`;

const FeatureSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;

  margin-bottom: 5vh;
  margin-top: 5vh;

  @media (orientation: portrait) {
    flex-direction: column;
    margin-bottom: 3vh;
    padding: 2vh;
  }
`;

const TextContentLeft = styled.div`
  flex: 1;
  padding-left: 5vh;
  color: rgba(7,55,99);

  @media (max-width: 768px) {
    text-align: center;
    padding-right: 0;
    margin-bottom: 20px;
  }
   
  @media (orientation: portrait) {
    padding-left: 0;
    order: 1;
  }
`;

const ImageBox = styled.img`
  border-radius: 1.5vh;
  width: 75vh;
  height: auto;
  margin-left: 15vh;
  
  @media (orientation: portrait) {
    width: 30vh;
    margin-left: 0vh;
    margin-top: 5vh;
  }
`;

const FeatureHeading = styled.h2`
  font-size: 5vh;
  margin-bottom: 2vh;
  
  @media (orientation: portrait) {
    font-size: 2vh;
  }
`;

const FeatureDescription = styled.p`
  font-size: 3vh;
  
  @media (orientation: portrait) {
    font-size: 1.8vh;
  }
`;

interface ExitIntentModalProps {
  show: boolean;
  onClose: () => void;
  handleDismissAndNavigate: () => void;
}

// Modal styling
const ModalOverlay = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #073763;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  color: white;
  max-width: 400px;
  width: 80%;
`;

const ModalButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 16px;
  &:hover {
    background-color: #218838;
  }
`;

const ExitIntentModal: React.FC<ExitIntentModalProps> = ({ show, onClose, handleDismissAndNavigate }) => {
  
  const navigate = useNavigate();

  const handleNoThanksClick = () => {
    localStorage.setItem('dismissedModal', 'true'); // Store dismissal in localStorage
    navigate('/features'); // Navigate to the features page
  };

  return (
    <ModalOverlay show={show}>
      <ModalContent>
        <h2>Wait! Get 10% Off Your First Order</h2>
        <p>Use the code <strong>FIRST10</strong> at checkout to receive 10% off your first order.</p>
        <ModalButton onClick={handleDismissAndNavigate}>Claim Discount</ModalButton>
        <p style={{ marginTop: '20px', cursor: 'pointer' }} onClick={handleNoThanksClick}>
          No thanks, I'll pass.
        </p>
      </ModalContent>
    </ModalOverlay>
  );
};

const Project: React.FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
  const [, setModalDismissed] = useState(false);
  const [preventNavigation,] = useState(true);

  useEffect(() => {
    const dismissedModal = localStorage.getItem('dismissedModal');
    if (!dismissedModal) {
      const handleMouseMove = (e: MouseEvent) => {
        if (e.clientY < 50) {
          setShowModal(true);
        }
      };

      const handleNavigation = (e: MouseEvent) => {
        if (preventNavigation) {
          e.preventDefault();
          const target = e.target as HTMLAnchorElement;
          if (target && target.href) {
            setPendingNavigation(target.href); // Store the pending navigation URL
          }
          setShowModal(true); // Show the modal
        }
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('click', handleNavigation);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('click', handleNavigation);
      };
    }
  }, [preventNavigation]);

  const handleModalClose = () => {
    setShowModal(false);
    setModalDismissed(true);
    localStorage.setItem('dismissedModal', 'true');
    if (pendingNavigation) {
      window.location.href = pendingNavigation; // Navigate to the stored URL
    }
  };

  const handleDismissAndNavigate = () => {
    setShowModal(false);
    localStorage.setItem('dismissedModal', 'true'); // Store dismissal in localStorage
    navigate('/loginpage?signup=true'); // Navigate to signup page
  };

  const handleDemoClick = () => {
    navigate('/fulldemo');
  };

  const handleQuoteClick = () => {
    navigate('/quotedemo');
  };

  const handlePriceClick = () => {
    navigate('/pricingdemo');
  };

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <IntroParagraph>
          Save Hours on Quoting, Focus More on What Matters.
        </IntroParagraph>
        <VideoContainer>
          <StyledIframe 
            src="https://www.youtube.com/embed/UvuI3MZtNJg?modestbranding=1&autohide=1&showinfo=0" 
            title="Project Price design demo"
            allowFullScreen
          />
        </VideoContainer>
        <SignUpSection /> 
        <SmallParagraph>
          Find out why we are becoming UK landscapers #1 Tool.
        </SmallParagraph>

        <FeatureSection>
          <TextContentLeft>
            <FeatureHeading>Easy to Use Interface</FeatureHeading>
            <FeatureDescription>
              Our user-friendly app allows you to simply plot your design. This is all we need to calculate your materials.
            </FeatureDescription>
          </TextContentLeft>
          <ImageBox src="/design.png" alt="Design Example" />
        </FeatureSection>

        <FeatureSection>
          <ImageBox src="/dashboard.png" alt="Dashboard Example" />
          <TextContentLeft>
            <FeatureHeading>Let Us Do the Hard Work</FeatureHeading>
            <FeatureDescription>
              Upload your measurements, tweak the design, and in seconds, your project is completely priced.
            </FeatureDescription>
          </TextContentLeft>
        </FeatureSection>

        <FeatureSection>
          <TextContentLeft>
            <FeatureHeading>Have Your Quote Ready in Seconds</FeatureHeading>
            <FeatureDescription>
              View the professional quote of the project, ready to send to your customer.
            </FeatureDescription>
          </TextContentLeft>
          <ImageBox src="/quoter.png" alt="Quoter Example" />
        </FeatureSection>
        <SignUpSection />
        <OtherVideosContainer>
          <VideoThumbnail onClick={handleDemoClick}>
            <img src="/laptopscreen.png" alt="Video 1" />
            <p>View the full set up guide</p>
          </VideoThumbnail>
          <VideoThumbnail onClick={handlePriceClick}>
            <img src="/laptoptable1.png" alt="Video 3" />
            <p>Find out more on pricing a project using Project Price</p>
          </VideoThumbnail>
          <VideoThumbnail onClick={handleQuoteClick}>
            <img src="/clipboardpaper.png" alt="Video 2" />
            <p>Check out our quoting tool</p>
          </VideoThumbnail>
        </OtherVideosContainer>
        <ExitIntentModal 
          show={showModal} 
          onClose={handleModalClose} 
          handleDismissAndNavigate={handleDismissAndNavigate} 
        />
      </BlogContainer>

      <Footer />
    </BackgroundContainer>
  );
};

export default Project;
